









import { defineComponent, inject, Ref } from '@vue/composition-api';
import { PAGE_SECTION_HEIGHT } from '~/components/storyblok/PageSection/helpers';

export default defineComponent({
  name: 'ATFLearnMore',
  inheritAttrs: false,
  setup() {
    const pageSectionHeight = inject<Ref<number>>(PAGE_SECTION_HEIGHT);

    return {
      handleLearnMore() {
        if (pageSectionHeight?.value && document) {
          const elementId = document.getElementById('chooseConnectCompete');

          elementId?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      },
    };
  },
});

export const PAGE_SECTION_HEIGHT = Symbol('pageSectionHeight');

export const componentWhiteList = new Set([
  'Banner',
  'SplitView',
  'Unique',
  'AboveTheFoldView',
  'GeneralContent',
  'Carousel',
  'ReviewsGrid',
  'Experiment',
  'AccessoryBundleProducts',
  'RelatedProducts',
  'AccessoryProducts',
  'Featured',
]);
